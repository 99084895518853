import React, { memo } from "react";
import NavbarSocialIcons from "src/components/navigation/NavbarSocialIcons";
import { LocalizedLink } from "src/components/commonComponents";
export const FooterCopyright = ({ isAcc }) => {
    return (
      <>
        {isAcc ? (
          <div className="tw-py-0 tw-my-0 tw-pb-[4px] tw-font-bold tw-font-Roboto tw-text-[14px] tw-text-center tw-w-fit  lg:tw-text-right tw-text-accruent_white tw-pr-0 lg:tw-pl-[86px] footer-bottom-text">
            © Copyright Accruent {new Date().getFullYear()}.
          </div>
        ) : (
          <div className=" tw-w-full lg:tw-min-w-[320px]">
            <div className=" tw-py-0">
              <LocalizedLink to="https://www.accruent.com/">
                <img
                  src="/accruent-logo-white.svg"
                  alt="Home"
                  width="144"
                  height="36"
                  className="tw-inline tw-w-[189px] tw-h-[47px]"
                  decoding = "async"
                />
              </LocalizedLink>
            </div>
            <div className=" tw-pt-[24px] tw-pb-[8px] tw-px-0 footer-social-icons">
              <NavbarSocialIcons />
            </div>
          </div>
        )}
      </>
    )
  }