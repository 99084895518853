import React, { memo } from "react";
import { LocalizedLink } from "src/components/commonComponents";
export const FooterData = ({ footerData }) => {
    return (
      <>
        {footerData && footerData.map(item => (
          <div className=" tw-col-span-6 lg:tw-col-span-3 tw-py-[12px] tw-px-4 lg:tw-p-[12px] lg:tw-pr-[4px] tw-w-[50%] column-wrapper" key={item.name}>
            <span className="title">
              {item.name}
            </span>
            <div className="subtitle-wrapper">
              {item.children && item.children.map(itemChild => (
                <ul className=" first-of-type:tw-pt-4 first-of-type:lg:tw-pt-[24px]" aria-labelledby={itemChild.name} key={itemChild.name}>
                  <li className="subtitle">
                    <LocalizedLink className="tw-text-accruent_dark_gray hover:tw-text-[#242424] hover:tw-underline tw-font-Roboto" to={itemChild.link}>{itemChild.name}</LocalizedLink>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        ))}
        
        {/* This code is required for masonary layout  */}
          <span class="column-wrapper item-break"></span>
          <span class="column-wrapper item-break"></span>
          <span class="column-wrapper item-break"></span>
        {/* -- */}
      </>
    )
  }