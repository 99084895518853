import React, { useContext, useEffect, useState, useRef } from "react";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import site_vars from "src/get_site_data";
import * as styles from "./_footer.module.scss"
import useWindowSize from "src/components/helpers/useWindowSize"
import { getMultiRowContainerHeight } from "src/utils";
import { FOOTER_COLUMN_PER_ROW_DESKTOP, FOOTER_COLUMN_PER_ROW_MOBILE, MOBILE_VIEWPORT_WIDTH } from "src/constants"
import { FooterLogo } from "./footerLogo";
import { FooterData } from "./footerData";
import { FooterCopyright } from "./footerCopyright";
import { FooterBottomText } from "./footerBottomText";

const Footer = ({ footerProps }) => {
  const { footerData, copyrightFooterData, secondaryFooterData } = useContext(ResourcesContext);
  const [isEms, setIsEms] = useState(false);
  const [isAcc, setIsAcc] = useState(false);
  const [logoUrl, setLogoUrl] = useState('/');
  const footerDataContainer = useRef(null)
  const { width } = useWindowSize();

  useEffect(() => {
    if (footerProps?.location?.pathname?.includes('/uk')) {
      setLogoUrl('/uk');
    };
  }, [footerProps]);

  let pageType = footerProps?.pageType
  useEffect(() => {
    setIsEms(site_vars.drupal_site_id === 452);
    setIsAcc(site_vars.drupal_site_id === 451)
  }, [site_vars]);

  useEffect(() => {
    if (footerDataContainer?.current) {
      const numberOfColumnsPerRow = (width > MOBILE_VIEWPORT_WIDTH) ? FOOTER_COLUMN_PER_ROW_DESKTOP : FOOTER_COLUMN_PER_ROW_MOBILE
      footerDataContainer.current.style.maxHeight = getMultiRowContainerHeight(footerDataContainer.current, null, numberOfColumnsPerRow);
    }
  }, [width, footerDataContainer]);

  let logo = site_vars.logo
  const MemoisedFooterLogo = React.memo(FooterLogo);
  const MemoisedFooterData = React.memo(FooterData);
  const MemoisedFooterCopyright = React.memo(FooterCopyright);
  const MemoisedFooterText = React.memo(FooterBottomText);
  return (
    <div className={styles.footerContainer + " content-visibility-auto-mobile"}>
    <div className={`footer-wrapper new-footer tw-w-full tw-overflow-hidden ${isEms ? 'is-ems' : ""}`}>
      <div className={isEms ? `tw-grid tw-grid-cols-12 tw-relative  tw-text-accruent_lightgrey footer-top ems` : ` tw-grid tw-grid-cols-12  tw-relative  tw-text-accruent_lightgrey footer-top tw-flex tw-flex-col tw-justify-end`}>
        <div className={` tw-col-span-12 lg:tw-col-span-3 tw-p-0 tw-mt-0 tw-text-center tw-pb-0  tw-pt-[32px] lg:tw-pt-[76px] lg:tw-pr-[12px] lg:tw-text-right footer-logo  tw-min-w-[200px] ${isEms ? 'is-ems' : ''}`}>
          <MemoisedFooterLogo logoUrl={logoUrl} isAcc={isAcc} isEms={isEms} logo={logo}/>
        </div>
        <div ref={footerDataContainer} className={`tw-grid tw-grid-cols-12 tw-col-span-12 lg:tw-col-span-9 tw-flex tw-m-0 tw-pt-[32px] tw-pb-[52px] lg:tw-pl-[74px] lg:tw-pt-[83px] lg:tw-pb=[76px] footer-data`}>
          <MemoisedFooterData footerData={footerData}/>
        </div>
      </div>
      <div className={`tw-grid tw-grid-cols-12 tw-bg-accruent_sapphire tw-py-[20px] lg:tw-py-[40px] tw-flex tw-items-center footer-bottom`}>
        <div className={` tw-col-span-12 lg:tw-col-span-3 tw-text-center footer-logo ${isAcc ? 'tw-pt-1 tw-flex tw-justify-center lg:tw-justify-end is-acc' : 'tw-flex tw-flex-col tw-items-end'}`}>
          <MemoisedFooterCopyright isAcc={isAcc}/>
        </div>
        <div className="tw-grid tw-grid-cols-12 tw-col-span-12 lg:tw-col-span-9  tw-text-white tw-text-center tw-leading-[32px] tw-pt-[12px] tw-pb-[18px] tw-px-[20px] tw-m-0 tw-font-Roboto lg:tw-p-0 lg:tw-pl-[86px] footer-bottom-text">
          <MemoisedFooterText isAcc={isAcc} copyrightFooterData={copyrightFooterData} secondaryFooterData={secondaryFooterData}/>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Footer;