import React, { memo } from "react";
import NavbarSocialIcons from "src/components/navigation/NavbarSocialIcons";
import { LocalizedLink } from "src/components/commonComponents";
export const FooterLogo = ({ logoUrl, isAcc, isEms, logo }) => {
  return (
    <>
      <LocalizedLink className=" tw-inline tw-w-auto" to={logoUrl}>
        <img
          src={logo}
          alt="Home"
          width="144"
          height="36"
          className={`tw-inline-block ${isAcc?"tw-w-[196px]":"tw-w-[70%] tw-ml-auto"}`}
          decoding = "async"
        />
      </LocalizedLink>
      {isAcc && (
        <div className={`tw-p-0 is-center footer-social-icons tw-min-w-[200px] lg:tw-flex lg:tw-justify-end`}>
          <div className="tw-py-0 footer-social-icons-content lg:tw-w-[196px]">
            <div className="lg:tw-pt-4 lg:tw-pb-2 tw-text-center tw-text-accruent_sapphire tw-font-bold tw-text-4">
              Follow us
            </div>
            <div className={` tw-text-center tw-text-accruent_sapphire`}>
              <NavbarSocialIcons />
            </div>
          </div>
        </div>
      )}
      <div className={`footer-border-top tw-absolute tw-rounded-[30px] tw-h-[6px] tw-top-0 tw-left-[-3px] tw-w-[50%] lg:tw-w-[24.5%] ${isEms ? 'is-ems tw-bg-[#2a682b]' : isAcc ? 'tw-bg-[#592b82]' : 'tw-bg-[#d13016]'}`}></div>
    </>
  )
}