import React, { memo } from "react";
import { LocalizedLink } from "src/components/commonComponents";
export const FooterBottomText = ({isAcc, copyrightFooterData, secondaryFooterData}) => {
  const secondaryFooterDataSize = secondaryFooterData?.length;
    return (
      <>
        {!isAcc && (
          <div className="tw-col-span-12 tw-py-1 tw-hidden lg:tw-block tw-text-left">
             {secondaryFooterData?.map((item, i) => {
                return (<>
                  <LocalizedLink className=" tw-text-[14px] tw-text-white hover:tw-text-secondary_color  hover:tw-underline" to={item.link}>{item.name}</LocalizedLink>{!(i<(secondaryFooterDataSize-1)) || ' | '}
                </>
                )
              })}
          </div>
        )}
        <div className="tw-col-span-12 tw-py-1 tw-text-center lg:tw-text-left">
          {copyrightFooterData?.map((item, i) => {
            return (<>
              <LocalizedLink className="tw-text-white hover:tw-text-[#6dd5fa] hover:tw-underline tw-font-Roboto tw-text-[14px]" to={item.link}>{item.name}</LocalizedLink>{' | '}
            </>
            )
          })}
  
          <a className="cookie-detail-link ot-sdk-show-settings tw-text-[#648BFF] tw-border-[1px] tw-border-[#648BFF] tw-rounded-[4px] tw-px-[4px] tw-text-[14px] hover:tw-text-[#6dd5fa] hover:tw-underline tw-inline-block  " id="ot-sdk-btn">
            Cookies Settings
          </a>
        </div>
        {!isAcc && <div className="tw-col-span-12 tw-pt-3 tw-font-bold tw-text-center lg:tw-text-left tw-text-[14px]">
          © Copyright Accruent {new Date().getFullYear()}.
        </div>}
      </>
    )
  }